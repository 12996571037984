._AppInfo {
	display: flex;
	flex-direction: row;
	align-items: center;

	._AppInfo_DNT {
		font-weight: 700;
		font-size: 20px;
		padding: 0 30px;
	}

	._AppInfo_status {
		padding: 0 30px;
		height: 55px;
		border-left: 1px solid #5E5E5E;
		display: flex;
		flex-direction: column;
		// align-items: flex-end;
		justify-content: center;

		._AppInfo_status_title {
			color: #DCE2E6;
		}

		._AppInfo_status_value {
			padding-top: 5px;
			color: #A5A5A5;
			display: flex;
			flex-direction: row;
			align-items: center;

			._AppInfo_status_value_circle {
				height: 10px;
				width: 10px;
				border-radius: 50%;
				background: #18A900;
				margin-right: 10px;
			}
		}
	}

	._AppInfo_time {
		padding: 0 30px;
		height: 55px;
		border-left: 1px solid #5E5E5E;
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;

		._AppInfo_time_title {
			color: #DCE2E6;
		}
		._AppInfo_time_value {
			padding-top: 5px;
			color: #A5A5A5;
		}
	}
}

// @media screen and (max-width: 768px) {
@media screen and (max-width: 1024px) {
	._AppInfo {
		flex-direction: row-reverse;

		._AppInfo_DNT {
			display: none;
		}

		._AppInfo_time {
			border-left: none;
		}
	}
}