body {
	// background: #0a0a0a;
	background: #0F0F0F;
	color: #dfe4e8;
	font: 18px/1.444 'Lexend', sans-serif;
	line-height: 1.15;
	// margin: 0 0 30px 0;
	margin: 0;
	height: 100%;
	// min-height: 100vh;
}

/* .closingBG{
	animation-duration: 0.5s !important;
	animation-name: background_hiding !important;
}

.closingModal{
	animation-duration: 0.5s !important;
	animation-name: modal_hiding !important;
}

@keyframes modal_showing {
	from{
		transform: scale(0);
	}

	to {
		transform: scale(1);
	}
} 

@keyframes modal_hiding {
	from{
		transform: scale(1);
	}

	to {
		transform: scale(0);
	}
}

@keyframes background_showing {
	from{
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes background_hiding {
	from{
		opacity: 1;
	}

	to {
		opacity: 0;
	}
} */