._Loader{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	opacity: 0;
	animation: _LoaderShowing .5s forwards;

	.innerLoader{
		color: rgba(255, 255, 255, 0.8);
	}
}

@keyframes _LoaderShowing {
	0% {opacity: 0;}
	100% {opacity: 1;}
}