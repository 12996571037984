._Header {
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	// justify-content: space-between;
	padding: 20px 30px;
	background-color: black;
	width: 100%;

	._Header_divider1 {
		flex-grow: 1;
	}

	._Header_rightBlock {

		._Header_rightBlock_accountBlock {
			box-sizing: border-box;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			width: 370px;
			border-left: 1px solid #5E5E5E;
			height: 55px;
			cursor: pointer;
			

			._Header_rightBlock_accountBlock_accountIcon {
				padding-right: 20px;
			}

			._Header_rightBlock_accountBlock_text {
				font-size: 23px;
				font-weight: 700;
				// color: linear-gradient(88.97deg, #AA6EE5 1.07%, #B0DCFF 86.71%);
				background: linear-gradient(88.97deg, #AA6EE5 1.07%, #B0DCFF 86.71%);
				-webkit-background-clip: text;
				background-clip: text;
				-webkit-text-fill-color: transparent;
			}

			._Header_rightBlock_accountBlock_walletIcon {
				margin-left: 20px;
				border: 2px solid #CAFFFF ;
				height: 35px;
				width: 35px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}
	}
}

@media screen and (max-width: 1024px) {
	._Header {

		._Header_divider2 {
			flex-grow: 1;
		}

		._Header_rightBlock{

			._Header_rightBlock_accountBlock {
				width: unset;
				border-left: none;

				/* ._Header_rightBlock_accountBlock_text {
					display: none;
				} */
			}
		}

	}	
}

@media screen and (max-width: 768px) {
	._Header {
		flex-direction: column;
		align-items: unset;

		._Header_rightBlock{

			._Header_rightBlock_accountBlock {
				justify-content: flex-end;
			}
		}
	}
}